import { PhaseColor, Sport } from '@hulanbv/toftennis';
import tennisBackgroundGreen from '../../../assets/images/swirl/tennis-green.png';
import tennisBackgroundOrange from '../../../assets/images/swirl/tennis-orange.png';
import tennisBackgroundRed from '../../../assets/images/swirl/tennis-red.png';
import padelBackgroundGreen from '../../../assets/images/swirl/padel-green.png';
import padelBackgroundOrange from '../../../assets/images/swirl/padel-orange.png';
import padelBackgroundRed from '../../../assets/images/swirl/padel-red.png';
import wheelchairTennisBackgroundRed from '../../../assets/images/swirl/wheelchair-tennis-red.png';
import wheelchairTennisBackgroundOrange from '../../../assets/images/swirl/wheelchair-tennis-orange.png';
import wheelchairTennisBackgroundGreen from '../../../assets/images/swirl/wheelchair-tennis-green.png';

const swirlBackgrounds: Record<Sport, Partial<Record<PhaseColor, string>>> = {
  [Sport.TENNIS]: {
    [PhaseColor.GREEN]: tennisBackgroundGreen,
    [PhaseColor.ORANGE]: tennisBackgroundOrange,
    [PhaseColor.RED]: tennisBackgroundRed,
  },
  [Sport.PADEL]: {
    [PhaseColor.GREEN]: padelBackgroundGreen,
    [PhaseColor.ORANGE]: padelBackgroundOrange,
    [PhaseColor.RED]: padelBackgroundRed,
  },
  [Sport.WHEELCHAIR_TENNIS]: {
    [PhaseColor.RED]: wheelchairTennisBackgroundRed,
    [PhaseColor.ORANGE]: wheelchairTennisBackgroundOrange,
    [PhaseColor.GREEN]: wheelchairTennisBackgroundGreen,
  },
};

export { swirlBackgrounds };
