import { IPhase, IPhaseLevel } from '@hulanbv/toftennis';
import { HTMLAttributes, useContext } from 'react';
import { useModalContext } from '../../../domain/common/hooks/modal/use-modal-context.hook';
import { join } from '../../../domain/common/utilities/join.utility';
import { ConfirmModalTemplate } from '../../templates/confirm-modal-template.component';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './user-phase-level-selector-element.module.css';
import { sportBallGraphics } from '../../../domain/common/constants/sport-ball-graphics.constant';
import { ProgressionManagerContext } from '../../../domain/common/hooks/use-progression-manager';
import { useNotificationContext } from '../../../domain/common/hooks/notification/use-notification-context.hook';

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;
  phase: IPhase;
};

function UserPhaseLevelSelector(props: Props): JSX.Element {
  const { masteredLevels, saveUserPhaseMastery } = useContext(
    ProgressionManagerContext,
  );
  const masteredLevel = masteredLevels?.[props.phase.phaseIndex];
  const { openModal } = useModalContext();
  const { add } = useNotificationContext();

  const handleLevelClick = async (phaseLevel: IPhaseLevel) => {
    let newLevel = phaseLevel.level;
    if (newLevel === masteredLevel) {
      newLevel -= 1;
    }
    if (masteredLevel && newLevel < masteredLevel) {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModalTemplate
          resolve={resolve}
          content={
            <p>
              Wil je de geselecteerde gebruiker(s) terugzetten naar niveau{' '}
              <strong>{newLevel}</strong> van{' '}
              <strong>{props.phase.skill}</strong>
            </p>
          }
        />
      ));

      if (!isConfirmed) {
        return;
      }
    }

    try {
      await saveUserPhaseMastery(phaseLevel.phaseId ?? '', newLevel);
    } catch (error) {
      add('Er is iets fout gegaan bij het opslaan van het niveau.', 'error');
    }
  };

  return (
    <FlexElement attributes={props.attributes} direction="row" gap={25}>
      {props.phase.phaseLevels?.map((phaseLevel, index) => (
        <div
          key={index}
          onClick={() => handleLevelClick(phaseLevel)}
          className={join(
            styles.level,
            phaseLevel.level <= (masteredLevel ?? 0) && styles.achieved,
          )}
          style={{
            backgroundImage:
              phaseLevel.level <= (masteredLevel ?? 0)
                ? `url(${sportBallGraphics[props.phase.sport]})`
                : undefined,
          }}
        />
      ))}
    </FlexElement>
  );
}

export { UserPhaseLevelSelector };
