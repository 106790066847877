import { IUser } from '@hulanbv/toftennis';
import { CrudService, IHttpOptions, IResponse } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<IUser> {
  constructor() {
    super([process.env.REACT_APP_API_URL, 'users'].join('/'), httpService);
  }

  async getByGroup(
    groupId: string,
    options?: IHttpOptions,
  ): Promise<IResponse<IUser[]>> {
    return this.http.get<IUser[]>(
      [this.controller, 'groups', groupId].join('/'),
      options,
    );
  }
}

const userService = new Service();

export { userService };
