import { FC, useContext, useMemo, useState } from 'react';
import { IUser, IUserScore } from '@hulanbv/toftennis';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ReactComponent as CrossIcon } from '../../assets/graphics/cross.svg';
import { TableRowElement } from '../elements/table-row-element/table-row.element';
import { ReactComponent as AllScoresIcon } from '../../assets/graphics/all-scores-filter.svg';
import { ReactComponent as HighestScoreIcon } from '../../assets/graphics/highest-score-filter.svg';
import { ReactComponent as AlphabeticalIcon } from '../../assets/graphics/alphabetical-filter.svg';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { ConfirmModalTemplate } from './confirm-modal-template.component';
import { useNotificationContext } from '../../domain/common/hooks/notification/use-notification-context.hook';
import { AddEventScoreModal } from './add-event-score-modal.template';
import { formatDate } from '../../domain/common/utilities/format-date.utility';
import { ProgressionManagerContext } from '../../domain/common/hooks/use-progression-manager';

type Filters = 'all' | 'highest' | 'alphabetical';
const filters: Filters[] = ['highest', 'alphabetical', 'all'];
const filterIcons: Record<Filters, JSX.Element> = {
  all: (
    <>
      Alle scores <AllScoresIcon height={'1.2em'} />
    </>
  ),
  highest: (
    <>
      Hoogste totaal
      <HighestScoreIcon height={'1.2em'} />
    </>
  ),
  alphabetical: (
    <>
      Alfabetisch <AlphabeticalIcon height={'1.2em'} />
    </>
  ),
};

const UserScoresTemplate: FC = () => {
  const { memberSelection, saveUserScore, deleteUserScore, userScores } =
    useContext(ProgressionManagerContext);
  const { openModal } = useModalContext();
  const { add: addNotification } = useNotificationContext();
  const [selectedFilter, setSelectedFilter] = useState<Filters>('highest');
  const memberById = memberSelection?.reduce<Record<string, IUser>>(
    (acc, member) => {
      acc[member.id ?? ''] = member;
      return acc;
    },
    {},
  );
  const userRecords: {
    user?: IUser;
    scores: IUserScore[];
    totalScore: number;
  }[] = useMemo(() => {
    const scoresByUserId = (userScores ?? []).reduce<
      Record<string, IUserScore[]>
    >((acc, userScore) => {
      acc[userScore.userId] = acc[userScore.userId] ?? [];
      acc[userScore.userId]?.push(userScore);

      return acc;
    }, {});

    return (memberSelection ?? []).map((member) => {
      const record = {
        user: member,
        scores: scoresByUserId[member.id ?? ''] ?? [],
        totalScore: 0,
      };
      record.totalScore += record.scores.reduce(
        (acc, score) => acc + score.score,
        0,
      );

      return record;
    });
  }, [memberSelection, userScores]);

  const sortedUserRecords = useMemo(
    () =>
      [...(userRecords ?? [])].sort((a, b) => {
        if (selectedFilter === 'alphabetical') {
          return a.user?.firstName?.localeCompare(b.user?.firstName ?? '') ?? 0;
        }
        return b.totalScore - a.totalScore;
      }),
    [selectedFilter, userRecords],
  );

  const handleDeleteClick = async (userScoreId: string) => {
    try {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModalTemplate
          resolve={resolve}
          cancelButtonText="Annuleren"
          confirmButtonText="Verwijderen"
          content={<p>Weet je zeker dat je deze score wilt verwijderen?</p>}
        />
      ));
      if (!isConfirmed) {
        return;
      }

      // publish the data
      await deleteUserScore(userScoreId);

      addNotification('De score is succesvol verwijderd', 'success');
    } catch {
      addNotification(
        'Er is iets misgegaan bij het verwijderen van de score',
        'error',
      );
    }
  };

  const handleAddClick = async (userId: string) => {
    try {
      const addedScore = await openModal<number>((resolve) => (
        <AddEventScoreModal resolve={resolve} />
      ));
      if (addedScore === null) {
        return;
      }

      // publish the data
      await saveUserScore({
        userId,
        score: addedScore,
      });

      addNotification('De score is toegevoegd aan de gebruiker', 'success');
    } catch {
      addNotification(
        'Er is iets misgegaan bij het toevoegen van de score',
        'error',
      );
    }
  };

  return (
    <FlexElement flex={1} justifyContent="flex-start">
      <FlexElement
        outerContentPadding
        direction="row"
        justifyContent="flex-end"
      >
        <ButtonElement
          attributes={{
            style: { flex: 0 },
            onClick: () => {
              const index = filters.indexOf(selectedFilter);
              setSelectedFilter(
                filters[(index + 1) % filters.length] ?? 'highest',
              );
            },
          }}
          flavour="blue"
          children={
            <FlexElement
              direction="row"
              attributes={{ style: { whiteSpace: 'nowrap' } }}
            >
              {filterIcons[selectedFilter]}
            </FlexElement>
          }
        />
      </FlexElement>

      {userRecords !== null && (
        <FlexElement gap={0}>
          {selectedFilter !== 'all' &&
            sortedUserRecords.map(({ user, scores, totalScore }) => (
              <TableRowElement
                onAddClick={() => handleAddClick(user?.id ?? '')}
                key={user?.id}
                title={`${user?.firstName} ${user?.lastName}`}
                subContent={
                  <FlexElement
                    direction="row"
                    attributes={{
                      style: { color: 'var(--brand-text-secondary)' },
                    }}
                  >
                    <p style={{ flex: 1 }}>
                      Totaal score <strong>{totalScore}</strong>
                    </p>
                    <p>
                      {scores.length} update{scores.length !== 1 && 's'}
                    </p>
                  </FlexElement>
                }
              />
            ))}

          {selectedFilter === 'all' &&
            userScores?.map(({ id, userId, score, createdAt }) => {
              const user = memberById[userId];
              return (
                <TableRowElement
                  onDeleteClick={() => handleDeleteClick(id ?? '')}
                  key={id ?? ''}
                  title={`${user?.firstName} ${user?.lastName}`}
                  subContent={
                    <FlexElement
                      direction="row"
                      attributes={{
                        style: { color: 'var(--brand-text-secondary)' },
                      }}
                    >
                      <p style={{ flex: 1 }}>
                        Score <strong>{score}</strong>
                      </p>
                      <p>{formatDate(createdAt)}</p>
                    </FlexElement>
                  }
                />
              );
            })}
        </FlexElement>
      )}

      {selectedFilter === 'all' && !userScores?.length && (
        <FlexElement flex={1}>
          <FlexElement
            direction="row"
            attributes={{
              style: {
                color: 'var(--brand-text-secondary)',
                fontSize: '1.2rem',
              },
            }}
          >
            <CrossIcon height={'1em'} fill="var(--brand-text-secondary)" />
            Geen scores beschikbaar
          </FlexElement>
        </FlexElement>
      )}
    </FlexElement>
  );
};

export { UserScoresTemplate };
