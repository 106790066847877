import {
  IClub,
  IGroup,
  ILevelAdvice,
  IPhase,
  IUser,
  IUserScore,
  PhaseColor,
  Role,
  Sport,
} from '@hulanbv/toftennis';
import {
  useEffect,
  useCallback,
  useState,
  createContext,
  useMemo,
} from 'react';
import { clubService } from '../../clubs/club.service';
import { groupService } from '../../groups/group.service';
import { userService } from '../../users/user.service';
import { userScoresService } from '../../user-scores/user-scores.service';
import { userPhaseService } from '../../user-phases/user-phase.service';
import { phaseService } from '../../phases/phase.service';
import { swirlBackgrounds } from '../constants/swirl-backgrounds.constant';
import { publishService } from '../../publishes/publish.service';
import { useAuthContext } from '../../authentication/use-auth-context.hook';

type UseProgressionManagerHook = {
  selectedClub: IClub | null;
  setSelectedClub: (club: IClub | null) => void;

  selectedGroup: IGroup | null;
  setSelectedGroup: (group: IGroup | null) => void;

  selectedMembers: IUser[];
  setSelectedMembers: (members: IUser[]) => void;

  selectedSport: Sport | null;
  setSelectedSport: (sport: Sport | null) => void;

  selectedPhaseColor: PhaseColor | null;
  setSelectedPhaseColor: (phaseColor: PhaseColor | null) => void;

  clubs: IClub[] | null;
  groups: IGroup[] | null;
  members: IUser[] | null;
  memberSelection: IUser[];
  levelAdvices: ILevelAdvice[] | null;
  phases: IPhase[] | null;

  masteredLevels: number[] | null;
  userScores: IUserScore[] | null;

  hasUnpublishedChanges: boolean;

  publishChanges: () => Promise<void>;

  saveUserPhaseMastery: (phaseId: string, level: number) => Promise<void>;

  saveUserScore: (userScore: IUserScore) => Promise<IUserScore>;
  deleteUserScore: (userScoreId: string) => Promise<void>;

  saveGroup: (group: IGroup | FormData) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<void>;

  state: 'fetching' | 'posting' | 'idle';
};

function useProgressionManager(): UseProgressionManagerHook {
  const { sessionToken } = useAuthContext();

  const [selectedClub, setSelectedClubValue] = useState<IClub | null>(null);
  const [selectedGroup, setSelectedGroupValue] = useState<IGroup | null>(null);
  const [selectedMembers, setSelectedMembers] = useState<IUser[]>([]);
  const [selectedSport, setSelectedSportValue] = useState<Sport | null>(null);
  const [selectedPhaseColor, setSelectedPhaseColorValue] =
    useState<PhaseColor | null>(null);

  const [clubs, setClubs] = useState<IClub[] | null>(null);
  const [groups, setGroups] = useState<IGroup[] | null>(null);
  const [members, setMembers] = useState<IUser[] | null>(null);
  const [levelAdvices, setLevelAdvices] = useState<ILevelAdvice[] | null>(null);
  const [phases, setPhases] = useState<IPhase[] | null>(null);
  const [latestPublishDate, setLatestPublishDate] = useState<Date | null>(null);
  const [latestChangeDate, setLatestChangeDate] = useState<Date | null>(null);

  const [masteredLevels, setMasteredLevels] = useState<number[] | null>(null);
  const [userScores, setUserScores] = useState<IUserScore[] | null>(null);

  const [state, setState] = useState<'fetching' | 'posting' | 'idle'>('idle');

  const memberSelection = useMemo(
    () =>
      (selectedMembers.length > 0
        ? selectedMembers
        : members?.filter((member) => member.role === Role.PLAYER)) ?? [],
    [members, selectedMembers],
  );

  const setSelectedPhaseColor = useCallback(
    (phaseColor: PhaseColor | null) => {
      if (
        selectedSport !== null &&
        phaseColor !== null &&
        swirlBackgrounds[selectedSport][phaseColor] === undefined
      ) {
        setSelectedPhaseColorValue(PhaseColor.RED);
      } else {
        setSelectedPhaseColorValue(phaseColor);
      }
    },
    [selectedSport],
  );

  const setSelectedSport = useCallback(
    (sport: Sport | null) => {
      if (
        sport !== null &&
        selectedPhaseColor !== null &&
        swirlBackgrounds[sport][selectedPhaseColor] === undefined
      ) {
        setSelectedPhaseColorValue(PhaseColor.RED);
      }
      setSelectedSportValue(sport);
    },
    [selectedPhaseColor],
  );

  const setSelectedGroup = useCallback(
    (group: IGroup | null) => {
      setSelectedGroupValue(group);
      setSelectedMembers([]);
      setSelectedSport(group?.sport ?? null);
      setSelectedPhaseColor(group?.color ?? null);

      const club = clubs?.find(({ id }) => id === group?.clubId) ?? null;
      setSelectedClubValue(club);
    },
    [clubs, setSelectedClubValue, setSelectedPhaseColor, setSelectedSport],
  );

  const setSelectedClub = useCallback((club: IClub | null) => {
    setSelectedGroupValue(null);
    setSelectedMembers([]);
    setSelectedClubValue(club);
  }, []);

  const saveUserPhaseMastery = useCallback(
    async (phaseId: string, level: number) => {
      setState('posting');

      // optimistically set the mastered levels to the new level
      const oldMasteredLevels = [...(masteredLevels ?? [])];
      const phase = phases?.find(({ id }) => id === phaseId);
      if (phase) {
        const newMasteredLevels = [...(oldMasteredLevels ?? [])];
        newMasteredLevels[phase.phaseIndex] = level;
        setMasteredLevels(newMasteredLevels);
      }

      try {
        // save the new level
        await userPhaseService.saveByPhaseIdAndLevel(
          phaseId,
          level,
          memberSelection?.map(({ id }) => id ?? ''),
          selectedGroup?.id,
        );

        // refetch the mastered levels
        if (selectedSport !== null && selectedPhaseColor !== null) {
          const { data: masteredLevels } =
            await userPhaseService.getMasteredLevelsBySportColor(
              selectedSport,
              selectedPhaseColor,
              memberSelection.length,
              {
                match: {
                  userId: { $in: memberSelection.map((member) => member.id) },
                },
                useRequestHeaders: true,
              },
            );
          setMasteredLevels(masteredLevels);
        }
      } catch (error) {
        // revert the changes
        setMasteredLevels(oldMasteredLevels);
        throw error;
      } finally {
        setState('idle');
      }

      setLatestChangeDate(new Date());
    },
    [
      masteredLevels,
      memberSelection,
      phases,
      selectedGroup?.id,
      selectedPhaseColor,
      selectedSport,
    ],
  );

  const saveUserScore = useCallback(
    async (userScore: IUserScore) => {
      setState('posting');

      if (userScore.id) {
        await userScoresService.patch(userScore);
      } else {
        await userScoresService.post(userScore);
      }

      const { data: userScores } = await userScoresService.getAll({
        match: { userId: { $in: memberSelection?.map(({ id }) => id) ?? [] } },
        useRequestHeaders: true,
      });
      setUserScores(userScores);

      setState('idle');

      return userScore;
    },
    [memberSelection],
  );

  const deleteUserScore = useCallback(
    async (userScoreId: string) => {
      setState('posting');

      await userScoresService.delete(userScoreId);

      const { data: userScores } = await userScoresService.getAll({
        match: { userId: { $in: memberSelection?.map(({ id }) => id) ?? [] } },
        useRequestHeaders: true,
      });
      setUserScores(userScores);

      setState('idle');
    },
    [memberSelection],
  );

  const saveGroup = useCallback(async (group: IGroup | FormData) => {
    setState('posting');

    if ((group instanceof FormData && group.get('_id')) || 'id' in group) {
      await groupService.patch(group);
    } else {
      await groupService.post(group);
    }

    const { data: groups } = await groupService.getAll();
    setGroups(groups);

    setState('idle');
  }, []);

  const deleteGroup = useCallback(async (groupId: string) => {
    setState('posting');

    await groupService.delete(groupId);

    const { data: groups } = await groupService.getAll();
    setGroups(groups);

    setState('idle');
  }, []);

  const publishChanges = useCallback(async () => {
    if (!sessionToken?.userId || !selectedClub?.id) {
      throw new Error('Missing session token or selected club');
    }

    setState('posting');

    const { data: publish } = await publishService.post({
      instructorId: sessionToken?.userId,
      clubId: selectedClub.id,
    });

    setLatestPublishDate(new Date(publish.createdAt ?? Date.now()));

    setState('idle');
  }, [selectedClub, sessionToken]);

  useEffect(() => {
    // fetch clubs, groups and level advices
    const fetchData = async () => {
      if (!sessionToken?.userId) {
        setClubs([]);
        setGroups([]);
        return;
      }

      setState('fetching');

      const { data: clubs } = await clubService.getAll();
      const { data: groups } = await groupService.getAll();
      const { data: latestPublish } = await publishService.getAll({
        match: { instructorId: sessionToken?.userId },
        sort: ['-createdAt'],
        limit: 1,
      });

      setClubs(clubs);
      setGroups(groups);

      setLatestPublishDate(
        latestPublish[0]?.createdAt
          ? new Date(latestPublish[0]?.createdAt)
          : null,
      );

      setState('idle');
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run once
  }, [sessionToken]);

  useEffect(() => {
    if (clubs !== null && groups !== null) {
      setSelectedGroup(groups[0] ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when available clubs and groups are set
  }, [clubs, groups]);

  useEffect(() => {
    // fetch members based on club or group
    const fetchMembers = async () => {
      setSelectedMembers([]);
      setMembers([]);

      if (!sessionToken?.userId) {
        return;
      }

      setState('fetching');

      if (selectedGroup) {
        const { data: members } = await userService.getByGroup(
          selectedGroup.id ?? '',
        );
        setMembers(members);
      } else if (selectedClub) {
        const { data: members } = await userService.getAll({
          match: { clubIds: selectedClub.id },
        });
        setMembers(members);
      }

      setState('idle');
    };

    fetchMembers();
  }, [selectedClub, selectedGroup, sessionToken]);

  useEffect(() => {
    const fetchData = async () => {
      setUserScores(null);

      if (!sessionToken?.userId || !memberSelection.length || !phases?.length) {
        return;
      }

      setState('fetching');

      const { data: userScores } = await userScoresService.getAll({
        match: { userId: { $in: memberSelection?.map(({ id }) => id) ?? [] } },
        useRequestHeaders: true,
      });
      setUserScores(userScores);

      setState('idle');
    };
    fetchData();
  }, [memberSelection, members, phases, sessionToken]);

  useEffect(() => {
    const fetchData = async () => {
      setLevelAdvices(null);

      if (!sessionToken?.userId || !memberSelection.length || !phases?.length) {
        return;
      }

      setState('fetching');

      const { data: levelAdvices } = await userPhaseService.getAdvicesLog(
        memberSelection.length,
        {
          useRequestHeaders: true,
          match: {
            userId: { $in: memberSelection.map(({ id }) => id) },
            phaseLevelId: {
              $in: (phases ?? [])
                .map(({ phaseLevels }) =>
                  (phaseLevels ?? []).map(({ id }) => id),
                )
                .flat(),
            },
          },
        },
      );

      setLevelAdvices(levelAdvices);
      setState('idle');
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- fetch on latestChangeDate
  }, [latestChangeDate, sessionToken]);

  useEffect(() => {
    const fetchPhases = async () => {
      setPhases(null);

      if (
        !sessionToken?.userId ||
        selectedSport === null ||
        selectedPhaseColor === null
      ) {
        return;
      }

      setState('fetching');

      const { data: phases } = await phaseService.getAll({
        match: { sport: selectedSport, color: selectedPhaseColor },
        populate: ['phaseLevels'],
      });
      setPhases(phases);

      setState('idle');
    };
    fetchPhases();
  }, [selectedSport, selectedPhaseColor, sessionToken]);

  useEffect(() => {
    // fetch highest user phases
    const fetchData = async () => {
      setMasteredLevels(null);
      setLatestChangeDate(null);

      if (
        !sessionToken?.userId ||
        !memberSelection.length ||
        selectedSport === null ||
        selectedPhaseColor === null
      ) {
        return;
      }

      setState('fetching');

      const { data: masteredLevels } =
        await userPhaseService.getMasteredLevelsBySportColor(
          selectedSport,
          selectedPhaseColor,
          memberSelection.length,
          {
            match: {
              userId: { $in: memberSelection.map((member) => member.id) },
            },
            useRequestHeaders: true,
          },
        );
      const { data: lastestUserPhase } = await userPhaseService.getBySportColor(
        selectedSport,
        selectedPhaseColor,
        {
          match: { userId: { $in: memberSelection.map(({ id }) => id) } },
          useRequestHeaders: true,
          sort: ['-createdAt'],
          limit: 1,
        },
      );

      setLatestChangeDate(
        !!lastestUserPhase[0]?.createdAt && !!latestPublishDate
          ? new Date(lastestUserPhase[0].createdAt)
          : null,
      );
      setMasteredLevels(masteredLevels);

      setState('idle');
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't refetch on latestPublishDate change
  }, [selectedSport, selectedPhaseColor, memberSelection, sessionToken]);

  return {
    selectedClub,
    setSelectedClub,
    selectedGroup,
    setSelectedGroup,
    selectedMembers,
    memberSelection,
    setSelectedMembers,
    selectedSport,
    setSelectedSport,
    selectedPhaseColor,
    setSelectedPhaseColor,
    clubs,
    phases,
    groups,
    members,
    levelAdvices,
    masteredLevels,
    userScores,
    saveUserPhaseMastery,
    saveUserScore,
    saveGroup,
    deleteGroup,
    state,
    hasUnpublishedChanges:
      !!latestChangeDate &&
      (!latestPublishDate || latestChangeDate > latestPublishDate),
    publishChanges,
    deleteUserScore,
  };
}

const notImplementedAsync = async () => {
  throw new Error('Not implemented');
};
const notImplemented = () => {
  throw new Error('Not implemented');
};

const ProgressionManagerContext = createContext<UseProgressionManagerHook>({
  clubs: null,
  groups: null,
  masteredLevels: null,
  levelAdvices: null,
  members: null,
  saveGroup: notImplementedAsync,
  saveUserScore: notImplementedAsync,
  selectedClub: null,
  selectedGroup: null,
  selectedMembers: [],
  memberSelection: [],
  selectedPhaseColor: null,
  selectedSport: null,
  setSelectedClub: notImplemented,
  setSelectedGroup: notImplemented,
  setSelectedMembers: notImplemented,
  setSelectedPhaseColor: notImplemented,
  setSelectedSport: notImplemented,
  state: 'idle',
  saveUserPhaseMastery: notImplementedAsync,
  userScores: null,
  deleteGroup: notImplementedAsync,
  phases: null,
  hasUnpublishedChanges: false,
  publishChanges: notImplementedAsync,
  deleteUserScore: notImplementedAsync,
});

export { useProgressionManager, ProgressionManagerContext };
