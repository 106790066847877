import {
  ComponentProps,
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './async-flex-element.module.css';

type Props = PropsWithChildren<{
  flexProps?: ComponentProps<typeof FlexElement>;
  isLoading?: boolean;
}>;

function AsyncFlexElement(props: Props): JSX.Element {
  const elementRef = useRef<HTMLDivElement>(null);
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const snapshotRef: MutableRefObject<string | null> = useRef<string>(null);

  useEffect(() => {
    let closeTimeout: number | null = null;
    if (props.isLoading) {
      setShouldShowLoader(true);
    } else {
      closeTimeout = window.setTimeout(() => setShouldShowLoader(false), 100);
    }

    return () => {
      if (closeTimeout !== null) {
        window.clearTimeout(closeTimeout);
      }
    };
  }, [props.isLoading]);

  // store a snapshot of the children when the component is not yet loading
  if (!props.isLoading) {
    snapshotRef.current = elementRef.current?.innerHTML ?? '';
  }

  // if we have a snapshot and the loader should be shown, show the snapshot instead of the children
  const shouldShowSnapshot = snapshotRef.current && shouldShowLoader;
  return (
    <FlexElement
      elementRef={elementRef}
      {...{
        ...props.flexProps,
        attributes: {
          ...props.flexProps?.attributes,
          className: join(
            props.flexProps?.attributes?.className,
            styles.flex,
            !shouldShowLoader && styles.hasLoaded,
          ),

          dangerouslySetInnerHTML: shouldShowSnapshot
            ? // eslint-disable-next-line @typescript-eslint/naming-convention -- used for rendering the snapshot
              { __html: snapshotRef.current ?? '' }
            : undefined,
        },
      }}
    >
      {!shouldShowSnapshot ? props.children : undefined}
    </FlexElement>
  );
}

export { AsyncFlexElement };
