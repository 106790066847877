import { PhaseColor } from '@hulanbv/toftennis';
import { useContext } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './phase-color-selector-element.module.css';
import { phaseColorLevelBackgroundColors } from '../../../domain/user-phases/constants/phase-color-level-background-colors.constant';
import { ProgressionManagerContext } from '../../../domain/common/hooks/use-progression-manager';

function PhaseColorSelector(): JSX.Element {
  const { selectedSport, selectedPhaseColor, setSelectedPhaseColor } =
    useContext(ProgressionManagerContext);

  return (
    <FlexElement flex={0} direction="row" gap={15}>
      {[PhaseColor.RED, PhaseColor.ORANGE, PhaseColor.GREEN].map((color) => {
        if (
          selectedSport === null ||
          !phaseColorLevelBackgroundColors[selectedSport][color]
        ) {
          return null;
        }
        return (
          <div
            key={color}
            onClick={() => setSelectedPhaseColor(+color)}
            className={join(
              styles.phaseColorItem,
              color === selectedPhaseColor && styles.selected,
            )}
            style={{
              backgroundColor:
                phaseColorLevelBackgroundColors[selectedSport][color]?.[1],
            }}
          ></div>
        );
      })}
    </FlexElement>
  );
}

export { PhaseColorSelector };
