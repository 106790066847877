import { marked } from 'marked';
import { useContext, useMemo, useState } from 'react';
import { ILevelAdvice, IPhase, IPhaseLevel, IUser } from '@hulanbv/toftennis';
import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ReactComponent as ArrowLeftSvg } from '../../assets/graphics/arrow-left-icon.svg';
import { ReactComponent as ArrowRightSvg } from '../../assets/graphics/arrow-right-icon.svg';
import { ReactComponent as CheckmarkSvg } from '../../assets/graphics/checkmark-icon.svg';
import { ProgressionManagerContext } from '../../domain/common/hooks/use-progression-manager';
import { ChipElement } from '../elements/chip-element/chip-element.component';

type Props = {
  user?: IUser;
  advices: ILevelAdvice[];
};

function LevelAdviceModalContentTemplate(props: Props): JSX.Element {
  const { phases } = useContext(ProgressionManagerContext);
  const { closeModal } = useModalContext();
  const [adviceIndex, setAdviceIndex] = useState(0);
  const phaseById = useMemo(
    () =>
      (phases ?? []).reduce<Record<string, IPhase>>((acc, phase) => {
        acc[phase.id ?? ''] = phase;
        return acc;
      }, {}),
    [phases],
  );
  const phaseLevelByLevelId = useMemo(
    () =>
      (phases ?? []).reduce<Record<string, IPhaseLevel>>((acc, phase) => {
        phase.phaseLevels?.forEach((phaseLevel) => {
          acc[phaseLevel.id ?? ''] = phaseLevel;
        });

        return acc;
      }, {}),
    [phases],
  );

  const advice = props.advices[adviceIndex];
  return (
    <FlexElement alignItems="flex-start" gap={30}>
      <FlexElement alignItems="flex-start">
        <h2>Niveau advies</h2>
        {props.user && (
          <p>
            {props.user.firstName} {props.user.lastName}
          </p>
        )}
      </FlexElement>
      <FlexElement alignItems="flex-start">
        {advice && (
          <FlexElement
            alignItems="flex-start"
            attributes={{
              dangerouslySetInnerHTML: {
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Component property
                __html: marked(advice.advice ?? ''),
              },
            }}
          />
        )}
        {!advice && (
          <p>
            Geen advies beschikbaar. Behaal niveaus om gepersonaliseerd advies
            vrij te spelen.
          </p>
        )}
      </FlexElement>
      <FlexElement direction="row" justifyContent="flex-start" gap={8}>
        {advice?.requiredPhaseLevelIds.map((phaseLevelId) => {
          const phaseLevel = phaseLevelByLevelId[phaseLevelId];
          const phase = phaseById[phaseLevel?.phaseId ?? ''];
          if (!phaseLevel || !phase) {
            return <></>;
          }
          return (
            <ChipElement key={phaseLevelId}>
              <FlexElement direction="row" gap={4}>
                <CheckmarkSvg width={8} />
                {phase.title} {phaseLevel.level}
              </FlexElement>
            </ChipElement>
          );
        })}
      </FlexElement>
      {advice && (
        <FlexElement direction="row" justifyContent="space-between">
          <ButtonElement
            fitContent
            attributes={{
              onClick: () => setAdviceIndex(adviceIndex - 1),
              disabled: adviceIndex <= 0,
            }}
          >
            <ArrowLeftSvg />
          </ButtonElement>
          <p>
            {adviceIndex + 1}/{props.advices.length}
          </p>
          <ButtonElement
            fitContent
            attributes={{
              onClick: () => setAdviceIndex(adviceIndex + 1),
              disabled: adviceIndex >= props.advices.length - 1,
            }}
          >
            <ArrowRightSvg />
          </ButtonElement>
        </FlexElement>
      )}
      <ButtonElement flavour="blue" attributes={{ onClick: closeModal }}>
        Sluiten
      </ButtonElement>
    </FlexElement>
  );
}

export { LevelAdviceModalContentTemplate };
