import {
  HTMLAttributes,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IUser, Role } from '@hulanbv/toftennis';
import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './member-select-element.module.css';
import { ReactComponent as FilledArrowDownSvg } from '../../../assets/graphics/filled-arrow-down-icon.svg';
import { EllipsisElement } from '../ellipsis-element/ellipsis-element.component';
import { ProgressionManagerContext } from '../../../domain/common/hooks/use-progression-manager';
import { ReactComponent as InfoSvg } from '../../../assets/graphics/info.svg';
import { useModalContext } from '../../../domain/common/hooks/modal/use-modal-context.hook';
import { LevelAdviceModalContentTemplate } from '../../templates/level-advice-modal-content-template';
import { userPhaseService } from '../../../domain/user-phases/user-phase.service';

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;
  hasDarkText?: boolean;
  isDisabled?: boolean;
};

// eslint-disable-next-line complexity -- we need to handle a lot of states
function MemberSelectElement(props: Props): JSX.Element {
  const { members, selectedMembers, setSelectedMembers, phases } = useContext(
    ProgressionManagerContext,
  );
  const { openModal } = useModalContext();
  const [isOpen, setIsOpen] = useState(false);
  const blurSelectBox = useCallback(() => {
    (document.activeElement as HTMLElement)?.blur();
  }, []);

  const players = useMemo(
    () => members?.filter((member) => member.role === Role.PLAYER) ?? [],
    [members],
  );

  const selectedMemberIds = useMemo(
    () => selectedMembers.map(({ id }) => id),
    [selectedMembers],
  );

  const handleSelectMember = (member: IUser) => {
    const newSelection = selectedMembers.filter(({ id }) => id !== member.id);
    if (selectedMembers.length === newSelection.length) {
      newSelection.push(member);
    }

    setSelectedMembers(newSelection);
  };

  const onInfoClick = useCallback(
    async (member: IUser) => {
      const { data: levelAdvices } = await userPhaseService.getAdvicesLog(1, {
        useRequestHeaders: true,
        match: {
          userId: member.id,
          phaseLevelId: {
            $in: (phases ?? [])
              .map((phase) =>
                phase.phaseLevels?.map((phaseLevel) => phaseLevel.id),
              )
              .flat(),
          },
        },
      });

      await openModal(() => (
        <LevelAdviceModalContentTemplate
          advices={levelAdvices ?? []}
          user={member}
        />
      ));
    },
    [openModal, phases],
  );

  return (
    <div
      {...{
        ...props.attributes,
        className: join(
          styles.select,
          isOpen && styles.opened,
          props.hasDarkText && styles.darkText,
          props.attributes?.className,
        ),
        onFocus: () => !props.isDisabled && setIsOpen(true),
        onBlur: () => setIsOpen(false),
        tabIndex: -1,
      }}
    >
      <div className={styles.valueContainer}>
        <FlexElement minHeight={25} direction="row" justifyContent="flex-start">
          <EllipsisElement>
            {[selectedMembers[0]?.firstName, selectedMembers[0]?.lastName]
              .filter(Boolean)
              .join(' ') || 'Alle spelers'}
          </EllipsisElement>
        </FlexElement>

        <FlexElement
          attributes={{ className: styles.metadataContainer }}
          direction="row"
          justifyContent="flex-end"
          minWidth="fit-content"
          gap={5}
        >
          <FilledArrowDownSvg
            width={25}
            height={25}
            className={styles.dropdownArrow}
          />
        </FlexElement>

        <FlexElement
          attributes={{ className: styles.metadataContainer }}
          direction="row"
          justifyContent="flex-end"
          minWidth="fit-content"
          gap={5}
        >
          {selectedMembers.length > 1 && (
            <div className={styles.selectionCounter}>
              +{selectedMembers.length - 1}
            </div>
          )}
          <FilledArrowDownSvg
            width={25}
            height={25}
            className={styles.dropdownArrow}
          />
        </FlexElement>
        {isOpen && (
          <div onClick={blurSelectBox} className={styles.deselector} />
        )}
      </div>

      {isOpen && (
        <div className={styles.dropdown}>
          {players?.map((member) => (
            <FlexElement
              direction="row"
              justifyContent="space-between"
              attributes={{
                className: join(
                  styles.option,
                  selectedMemberIds.includes(member.id ?? '') &&
                    styles.selectedOption,
                ),
                onClick: () => handleSelectMember(member),
              }}
              key={member.id}
            >
              <EllipsisElement>
                {[member.firstName, member.lastName].join(' ')}
              </EllipsisElement>
              <InfoSvg
                width={16}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onInfoClick(member);
                }}
              />
            </FlexElement>
          ))}

          {!players?.length && (
            <FlexElement
              attributes={{
                className: join(styles.option),
                style: { textAlign: 'center' },
              }}
            >
              Geen opties beschikbaar
            </FlexElement>
          )}
        </div>
      )}
    </div>
  );
}

export { MemberSelectElement };
