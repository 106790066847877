import { IPhase, IPhaseLevel } from '@hulanbv/toftennis';
import { Fragment, useContext } from 'react';
import { join } from '../../../../domain/common/utilities/join.utility';
import styles from './swirl-slice-element.module.css';
import { phaseColorLevelBackgroundColors } from '../../../../domain/user-phases/constants/phase-color-level-background-colors.constant';
import { sportBallGraphics } from '../../../../domain/common/constants/sport-ball-graphics.constant';
import { ProgressionManagerContext } from '../../../../domain/common/hooks/use-progression-manager';
import { ConfirmModalTemplate } from '../../../templates/confirm-modal-template.component';
import { useModalContext } from '../../../../domain/common/hooks/modal/use-modal-context.hook';
import { useNotificationContext } from '../../../../domain/common/hooks/notification/use-notification-context.hook';

type Props = {
  phase: IPhase;
  currentLevel?: number;
};

function SwirlSliceElement(props: Props): JSX.Element {
  const { saveUserPhaseMastery, masteredLevels } = useContext(
    ProgressionManagerContext,
  );
  const masteredLevel = masteredLevels?.[props.phase.phaseIndex];
  const { openModal } = useModalContext();
  const { add } = useNotificationContext();

  const handleLevelClick = async (phaseLevel: IPhaseLevel) => {
    let newLevel = phaseLevel.level;
    if (newLevel === masteredLevel) {
      newLevel -= 1;
    }
    if (masteredLevel && newLevel < masteredLevel) {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModalTemplate
          resolve={resolve}
          content={
            <p>
              Wil je de geselecteerde gebruiker(s) terugzetten naar niveau{' '}
              <strong>{newLevel}</strong> van{' '}
              <strong>{props.phase.skill}</strong>
            </p>
          }
        />
      ));

      if (!isConfirmed) {
        return;
      }
    }

    try {
      await saveUserPhaseMastery(phaseLevel.phaseId ?? '', newLevel);
    } catch (error) {
      add('Er is iets fout gegaan bij het opslaan van het niveau.', 'error');
    }
  };
  return (
    <Fragment>
      <div className={styles.levels}>
        {props.phase.phaseLevels
          ?.slice(0, 3)
          ?.map((phaseLevel: IPhaseLevel, index: number) => (
            <div
              key={phaseLevel.id}
              className={join(
                styles.level,
                phaseLevel.level <= (props.currentLevel ?? 0) &&
                  styles.achieved,
              )}
              style={{
                backgroundImage:
                  phaseLevel.level <= (props.currentLevel ?? 0)
                    ? `url(${sportBallGraphics[props.phase.sport]})`
                    : undefined,
                backgroundColor:
                  phaseColorLevelBackgroundColors[props.phase.sport][
                    props.phase.color
                  ]?.[1],
              }}
              onClick={() => handleLevelClick(phaseLevel)}
            />
          ))}
      </div>
      <p className={styles.name}>{props.phase.skill}</p>
      <h1 className={styles.identifier}>{props.phase.phaseIndex + 1}</h1>
    </Fragment>
  );
}

export { SwirlSliceElement };
