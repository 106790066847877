import { useContext } from 'react';
import { ProgressionManagerContext } from '../../domain/common/hooks/use-progression-manager';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { UserPhaseListItemElement } from '../elements/user-phase-list-item-element/user-phase-list-item-element.component';

function UserPhasesListTemplate(): JSX.Element {
  const { phases } = useContext(ProgressionManagerContext);
  return (
    <FlexElement gap={0}>
      {phases?.map((phase) => (
        <UserPhaseListItemElement key={phase.phaseIndex} phase={phase} />
      ))}
    </FlexElement>
  );
}

export { UserPhasesListTemplate };
