/* eslint-disable max-lines -- complex method */
import { useMemo, useState, useContext } from 'react';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { routable } from '../../domain/common/utilities/routable.utility';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { PhaseLevelsTemplate } from '../templates/phase-levels-template';
import { ProgressionManagerContext } from '../../domain/common/hooks/use-progression-manager';
import { CliqueSelectElement } from '../elements/clique-select-element/clique-select-element.component';
import { MemberSelectElement } from '../elements/member-select-element/member-select-element.component';
import { UserScoresTemplate } from '../templates/user-scores';
import { GroupSettingsTemplate } from '../templates/group-settings.template';
import { AsyncFlexElement } from '../elements/async-flex-element/async-flex-element.component';

type Views = 'swirl' | 'list' | 'scores' | 'groups';

const GroupScreen = routable(
  {
    root: {
      name: () => 'Selecteer groep',
      path: () => '/group',
      isAccessible: () => hasRole(),
    },
  },
  // eslint-disable-next-line complexity -- we need to handle a lot of states
  () => {
    const [activeView, setActiveView] = useState<Views>('swirl');
    const { selectedClub, clubs, masteredLevels, phases } = useContext(
      ProgressionManagerContext,
    );

    const views: [Views, string][] = useMemo(() => {
      const views: [Views, string][] = [
        ['swirl', 'Swirl'],
        ['list', "Info/video's"],
      ];
      if (selectedClub?.hasEventsModule) {
        views.push(['scores', 'TOF score']);
      }

      views.push(['groups', 'Mijn TOF']);

      return views;
    }, [selectedClub?.hasEventsModule]);

    return (
      <AsyncFlexElement
        flexProps={{
          justifyContent: 'flex-start',
          attributes: { style: { minHeight: '100vh' } },
        }}
        isLoading={clubs === null}
      >
        <>
          <FlexElement
            justifyContent="flex-start"
            direction="row"
            outerContentPadding
            flex={0}
            attributes={{
              style: { paddingTop: '15px' },
            }}
          >
            <CliqueSelectElement />
            <MemberSelectElement />
          </FlexElement>
          <AsyncFlexElement
            isLoading={masteredLevels === null || phases === null}
            flexProps={{
              flex: 1,
              justifyContent: 'flex-start',
              attributes: {
                style: {
                  background: 'white',
                  paddingTop: '15px',
                },
              },
            }}
          >
            <FlexElement
              justifyContent="flex-start"
              direction="row"
              flex={0}
              outerContentPadding
            >
              {views.map(([view, label]) => (
                <p
                  key={view}
                  onClick={() => setActiveView(view)}
                  style={{
                    opacity: activeView === view ? 1 : 0.5,
                    color: 'var(--brand-text-dark)',
                  }}
                >
                  {label}
                </p>
              ))}
            </FlexElement>
            <FlexElement flex={1} justifyContent="start">
              {['list', 'swirl'].includes(activeView) && (
                <PhaseLevelsTemplate
                  view={activeView === 'list' ? 'list' : 'swirl'}
                />
              )}
              {activeView === 'scores' && <UserScoresTemplate />}

              {activeView === 'groups' && <GroupSettingsTemplate />}
            </FlexElement>
          </AsyncFlexElement>
        </>
      </AsyncFlexElement>
    );
  },
);

export { GroupScreen };

/* eslint-enable max-lines -- complex method */
