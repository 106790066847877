/* eslint-disable max-lines -- complex method */
import { FC, useCallback, useContext, useEffect } from 'react';
import { ButtonIconElement } from '../elements/button-icon-element/button-icon-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { PhaseColorSelector } from '../elements/phase-color-selector-element/phase-color-selector-element.component';
import { SwirlElement } from '../elements/swirl-element/swirl-element.component';
import { ReactComponent as PublishIcon } from '../../assets/graphics/toftennis-publish.svg';
import { sportIcons } from '../../domain/common/constants/sport-icons.constant';
import { useSettingsContext } from '../../domain/settings/use-settings-context.hook';
import { ProgressionManagerContext } from '../../domain/common/hooks/use-progression-manager';
import { UserPhasesListTemplate } from './user-phases-list-template.component';
import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { useNotificationContext } from '../../domain/common/hooks/notification/use-notification-context.hook';
import { ConfirmModalTemplate } from './confirm-modal-template.component';

type Props = {
  view: 'swirl' | 'list';
};

const PhaseLevelsTemplate: FC<Props> = ({ view }) => {
  const {
    selectedClub,
    selectedSport,
    selectedPhaseColor,
    setSelectedSport,
    hasUnpublishedChanges,
    publishChanges,
  } = useContext(ProgressionManagerContext);
  const { activeSports } = useSettingsContext();
  const { modal, openModal } = useModalContext();
  const { add } = useNotificationContext();

  const handlePublishChanges = useCallback(async () => {
    if (!hasUnpublishedChanges) {
      add('Er is geen data gevonden om te publiceren.', 'error');
      return;
    }

    const isConfirmed = await openModal<boolean>((resolve) => (
      <ConfirmModalTemplate
        resolve={resolve}
        cancelButtonText="Annuleren"
        confirmButtonText="Publiceren"
        content={
          <p>
            Weet je zeker dat de ontwikkeling van al je leerlingen up-to-date
            is? Door op de knop te drukken worden gegevens gepubliceerd. Het
            maakt deze gegevens direct beschikbaar voor gedetailleerd advies.
          </p>
        }
      />
    ));
    if (isConfirmed) {
      await publishChanges();
    }
  }, [add, hasUnpublishedChanges, openModal, publishChanges]);

  // Effect invoked when the shouldShowListView state changes.
  useEffect(() => {
    if (modal) {
      return () => {
        // Don't manage the overflow state when a modal is shown.
      };
    }

    // When the list view is not shown, the Swirl will be rendered instead.
    // Since the Swirl makes use of touch events, we need to disable the
    // body's overflow property to prevent the page from being dragged rather
    // than the Swirl.
    if (view === 'swirl') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    // The auto scroll overflow state will be restored during the cleanup.
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [view, modal]);

  return (
    <>
      {selectedPhaseColor !== null && selectedSport !== null && (
        <FlexElement flex={1}>
          <FlexElement flex={1} alignItems="stretch" justifyContent="stretch">
            <FlexElement
              justifyContent="space-between"
              direction="row"
              outerContentPadding
            >
              <FlexElement direction="row" justifyContent="start">
                {activeSports.map((sport) => {
                  const Icon = sportIcons[sport];
                  return (
                    <Icon
                      key={sport}
                      height={'1.2em'}
                      color={
                        selectedSport === sport
                          ? 'var(--brand-knltb-blue)'
                          : 'var(--brand-text-secondary)'
                      }
                      onClick={() => setSelectedSport(sport)}
                    />
                  );
                })}
              </FlexElement>
              <PhaseColorSelector />
            </FlexElement>
            {view === 'swirl' && (
              <>
                <SwirlElement
                  attributes={{
                    style: {
                      flex: 1,
                      position: 'relative',
                    },
                  }}
                />
                {selectedClub?.hasPublishModule && (
                  <ButtonIconElement
                    flavour="dark-blue"
                    fitContent
                    icon={PublishIcon}
                    attributes={{ onClick: handlePublishChanges }}
                    isDisabled={hasUnpublishedChanges === false}
                  >
                    Publiceren
                  </ButtonIconElement>
                )}
              </>
            )}
            {view === 'list' && <UserPhasesListTemplate />}
          </FlexElement>
        </FlexElement>
      )}
    </>
  );
};

export { PhaseLevelsTemplate };
/* eslint-enable max-lines -- complex method */
