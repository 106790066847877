import {
  ILevelAdvice,
  IUserPhase,
  PhaseColor,
  Sport,
} from '@hulanbv/toftennis';
import { CrudService, IHttpOptions, IResponse } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<IUserPhase> {
  constructor() {
    super(
      [process.env.REACT_APP_API_URL, 'user-phases'].join('/'),
      httpService,
    );
  }

  getAdvicesLog(
    groupSize: number,
    options?: IHttpOptions,
  ): Promise<IResponse<ILevelAdvice[]>> {
    return this.http.get<ILevelAdvice[]>(
      [this.controller, 'level-advices', groupSize].join('/'),
      options,
    );
  }

  getMasteredLevelsBySportColor(
    selectedSport: Sport,
    selectedPhaseColor: PhaseColor,
    groupSize: number,
    options?: IHttpOptions,
  ): Promise<IResponse<number[]>> {
    return this.http.get<number[]>(
      [
        this.controller,
        'sport',
        selectedSport,
        'color',
        selectedPhaseColor,
        'mastered-levels',
        groupSize,
      ].join('/'),
      options,
    );
  }

  getBySportColor(sport: Sport, color: PhaseColor, options?: IHttpOptions) {
    return this.http.get<IUserPhase[]>(
      [this.controller, 'sport', sport, 'color', color].join('/'),
      options,
    );
  }

  saveByPhaseIdAndLevel(
    phaseId: string,
    level: number,
    userIds: string[],
    groupId?: string,
    options?: IHttpOptions,
  ) {
    const optionsWithGroupId = {
      ...options,
    };

    if (groupId) {
      optionsWithGroupId.groupId = groupId;
    }

    return this.http.post(
      [this.controller, 'phases', phaseId, 'level', level].join('/'),
      { userIds },
      optionsWithGroupId,
    );
  }
}

const userPhaseService = new Service();

export { userPhaseService };
