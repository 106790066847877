import {
  Fragment,
  HTMLAttributes,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IClub, IGroup } from '@hulanbv/toftennis';
import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './clique-select-element.module.css';
import { ReactComponent as FilledArrowDownSvg } from '../../../assets/graphics/filled-arrow-down-icon.svg';
import { EllipsisElement } from '../ellipsis-element/ellipsis-element.component';
import { ProgressionManagerContext } from '../../../domain/common/hooks/use-progression-manager';

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;
  hasDarkText?: boolean;
  isDisabled?: boolean;
};

// eslint-disable-next-line complexity -- we need to handle a lot of states
function CliqueSelectElement(props: Props): JSX.Element {
  const {
    clubs,
    groups,
    selectedClub,
    setSelectedClub,
    selectedGroup,
    setSelectedGroup,
  } = useContext(ProgressionManagerContext);
  const [isOpen, setIsOpen] = useState(false);
  const blurSelectBox = useCallback(() => {
    (document.activeElement as HTMLElement)?.blur();
  }, []);

  const groupsByClub = useMemo(
    () =>
      groups?.reduce<Record<string, IGroup[]>>((acc, group) => {
        acc[group.clubId] = acc[group.clubId] ?? [];
        acc[group.clubId]?.push(group);

        return acc;
      }, {}),
    [groups],
  );

  const handleSelectClub = (club: IClub) => {
    setSelectedClub(club);
    blurSelectBox();
  };

  const handleSelectGroup = (group: IGroup) => {
    setSelectedGroup(group);
    blurSelectBox();
  };

  return (
    <div
      {...{
        ...props.attributes,
        className: join(
          styles.select,
          isOpen && styles.opened,
          props.hasDarkText && styles.darkText,
          props.attributes?.className,
        ),
        onFocus: () => !props.isDisabled && setIsOpen(true),
        onBlur: () => setIsOpen(false),
        tabIndex: -1,
      }}
    >
      <div className={styles.valueContainer}>
        <FlexElement minHeight={25} direction="row" justifyContent="flex-start">
          <EllipsisElement>
            {(selectedGroup ?? selectedClub)?.name ?? 'Clubs/groepen'}
          </EllipsisElement>
        </FlexElement>

        <FlexElement
          attributes={{ className: styles.metadataContainer }}
          direction="row"
          justifyContent="flex-end"
          minWidth="fit-content"
          gap={5}
        >
          <FilledArrowDownSvg
            width={25}
            height={25}
            className={styles.dropdownArrow}
          />
        </FlexElement>
        {isOpen && (
          <div onClick={blurSelectBox} className={styles.deselector} />
        )}
      </div>

      {isOpen && (
        <div className={styles.dropdown}>
          {clubs?.map((club) => (
            <Fragment key={club.id}>
              <FlexElement
                direction="row"
                justifyContent="flex-start"
                attributes={{
                  className: join(
                    styles.option,
                    !selectedGroup &&
                      selectedClub?.id === club.id &&
                      styles.selectedOption,
                  ),
                  onClick: () => handleSelectClub(club),
                }}
              >
                <EllipsisElement>{club.name}</EllipsisElement>
              </FlexElement>

              {groupsByClub?.[club.id ?? '']?.map((group) => (
                <FlexElement
                  direction="row"
                  justifyContent="flex-start"
                  attributes={{
                    className: join(
                      styles.option,
                      selectedGroup?.id === group.id && styles.selectedOption,
                    ),
                    onClick: () => handleSelectGroup(group),
                    style: {
                      paddingLeft: '30px',
                    },
                  }}
                  key={group.id}
                >
                  <EllipsisElement>{group.name}</EllipsisElement>
                </FlexElement>
              ))}
            </Fragment>
          ))}

          {!clubs?.length && (
            <FlexElement
              attributes={{
                className: join(styles.option),
                style: { textAlign: 'center' },
              }}
            >
              Geen opties beschikbaar
            </FlexElement>
          )}
        </div>
      )}
    </div>
  );
}

export { CliqueSelectElement };
